export default [
  { path: "/", redirect: { name: "canias-home" } },



  {
    path: "/home",
    name: "canias-home",
    component: () => import("@/views/clients/canias/home/Home.vue"),
    meta: {
      pageTitle: "Inicio",
      breadcrumb: [
        {
          text: "Consola de Administración",
          active: true,
        },
      ],
    },
  },

  {
    path: "/canias-ajustes",
    name: "canias-ajustes",
    component: () => import("@/views/clients/canias/ajustes/Ajustes.vue"),
    meta: {
      pageTitle: "Ajustes y Restricciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },


  {
    path: "/canias-simulacion-escenarios",
    name: "canias-simulacion-escenarios",
    component: () =>
      import(
        "@/views/clients/canias/optimizaciones/HistoricoPlanificaciones.vue"
      ),
    meta: {
      pageTitle: "Simulación de Escenarios",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/historico-predicciones",
    name: "canias-historico-predicciones",
    component: () =>
      import(
        "@/views/clients/canias/historico-predicciones/HistoricoPredicciones2.vue"
      ),
    meta: {
      pageTitle: "Histórico de Predicciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/detalle-turno",
    name: "canias-detalle-turno",
    component: () =>
      import(
        "@/views/clients/canias/optimizaciones/DetalleTurno.vue"
      ),
    meta: {
      pageTitle: "Detalle Turno",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },



  {
    path: "/historico-comparacion-predicciones",
    name: "canias-historico-comparacion-predicciones",
    component: () =>
      import(
        "@/views/clients/canias/comparacion-predicciones/HistoricoComparacionPrediccion.vue"
      ),
    meta: {
      pageTitle: "Real vs Predicción",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/historico-data",
    name: "canias-historico-data",
    component: () =>
      import(
        "@/views/clients/canias/historico-automation-results/HistoricoAutomationResults.vue"
      ),
    meta: {
      pageTitle: "Histórico de Data",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },




  {
    path: "/optimizations/details/",
    name: "optimization-details",
    component: () =>
      import(
        "@/views/clients/canias/optimizaciones/DetallePlanificacion.vue"
      ),
    meta: {
      pageTitle: "Detalle de Optimización",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },


  {
    path: "/predicciones/compare-simulations/",
    name: "compare-simulations",
    component: () =>
      import(
        "@/views/clients/canias/optimizaciones/CompareOptimizations.vue"
      ),
    meta: {
      pageTitle: "Comparación de Simulaciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/predicciones/compare-predictions/",
    name: "compare-predictions",
    component: () =>
      import(
        "@/views/clients/canias/historico-predicciones/ComparePredictions.vue"
      ),
    meta: {
      pageTitle: "Comparación de Predicciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/ayuda/faq",
    name: "canias-faq",
    component: () => import("@/views/clients/canias/faq/Faq.vue"),
    meta: {
      pageTitle: "Inicio",
      breadcrumb: [
        {
          text: "Preguntas frecuentas",
          active: true,
        },
      ],
    },
  },
  // canias-politicas
  {
    path: "/ayuda/politicas",
    name: "canias-politicas",
    component: () => import("@/views/clients/canias/faq/Politicas.vue"),
    meta: {
      pageTitle: "Políticas de privacidad",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },


];
