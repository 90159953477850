
import ApiServices from "../apiServices";
import axios from '@axios'

export default class NestleApiServices extends ApiServices {
  constructor() {
    super("/nestle");

    this.historicoData = {  

      search: this.baseUrl + "/historicoData/search",

      presiones: {
        historicoConfig: this.baseUrl + "/historicoData/presiones/historico/config",
        historicoData: this.baseUrl + "/historicoData/presiones/historico/data",
        chart00: this.baseUrl + "/historicoData/presiones/chart00",
        stats00: this.baseUrl + "/historicoData/presiones/stats00",
      },

      temperatura: {
        historicoConfig: this.baseUrl + "/historicoData/temperatura/historico/config",
        historicoData: this.baseUrl + "/historicoData/temperatura/historico/data",
        chart00: this.baseUrl + "/historicoData/temperatura/chart00",
        stats00: this.baseUrl + "/historicoData/temperatura/stats00",
      },

      electrico: {
        historicoConfig: this.baseUrl + "/historicoData/electrico/historico/config",
        historicoData: this.baseUrl + "/historicoData/electrico/historico/data",
        chart00: this.baseUrl + "/historicoData/electrico/chart00",
        stats00: this.baseUrl + "/historicoData/electrico/stats00",
      },

      clima: {
        historicoConfig: this.baseUrl + "/historicoData/clima/historico/config",
        historicoData: this.baseUrl + "/historicoData/clima/historico/data",
        chart00: this.baseUrl + "/historicoData/clima/chart00",
        stats00: this.baseUrl + "/historicoData/clima/stats00",
      },


    };

    this.historicoPredicciones = {

      stats00: this.baseUrl + "/historicoPredicciones/stats00",
      stats01: this.baseUrl + "/historicoPredicciones/stats01",
      table00Config: this.baseUrl + "/historicoPredicciones/table00/config",
      table00Data: this.baseUrl + "/historicoPredicciones/table00/data", 

      table01Config: this.baseUrl + "/historicoPredicciones/table01/config",
      table01Data: this.baseUrl + "/historicoPredicciones/table01/data", 

      prediccionAceiteChart00: this.baseUrl + "/historicoPredicciones/chartPrediccionAceite",
      prediccionConsumoChart00: this.baseUrl + "/historicoPredicciones/chartPrediccionConsumo",      

      /* matrizRendimientoPorFundoChart00: this.baseUrl + "/historicoPredicciones/matrizRendimientoPorFundoChart00",
      rendimientoPromedioPorFundoChart00: this.baseUrl + "/historicoPredicciones/rendimientoPromedioPorFundoChart00",

      rendimientoPorEquipoChart00: this.baseUrl + "/historicoPredicciones/rendimientoPorEquipoChart00",


      */
  }

    this.historicoAutomationResultsConfig = this.baseUrl + "/historicoAutomationResults/config"
    this.historicoAutomationResultsData = this.baseUrl + "/historicoAutomationResults/data"
    this.historicoPrediccionesMezclas = {
      historicoPrediccionesMezclasStats: this.baseUrl + "/historicoPrediccionesMezclas/historicoPrediccionesMezclasStats",
  };



  this.historicoOptimizationsConfig = this.baseUrl + "/historicoOptimizations/config"
  this.historicoOptimizationsData = this.baseUrl + "/historicoOptimizations/data"


  this.detalleOptimizacion = {

    detalleOptimizacionTable: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable",
    detalleOptimizacionTable2: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable2",
    detalleOptimizacionParallelChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionParallelChart",
    detalleOptimizacionStats: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats",
    detalleOptimizacionGensChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionGensChart",

    detalleOptimizacionStats2: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats2",

    
    

};



  }
}


