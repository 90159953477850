

export default [
    {
        header: "Modules"
    },
    {
        icon: "HomeIcon",
        title: "Home",
        children:
        [

            {
                icon: "CircleIcon",
                title: "Inicio",
                route:
                {
                    name: "lorenzo-home",
                }
            },
                        

        ]
    },

    {
        icon: "DatabaseIcon",
        title: "Data",
        children:
        [

            {
                icon: "CircleIcon",
                title: "Carga de Archivo",
                route:
                {
                    name: "lorenzo-carga-data",
                }
            },            

            /*

            {
                icon: "CircleIcon",
                title: "Carga de Archivo",
                route:
                {
                    name: "dashboard-camanchaca-carga-data",
                }
            },            
            
            {
                icon: "CircleIcon",
                title: "Stock Jaulas",
                route:
                {
                    name: "camanchaca-stock-jaulas",
                }
            },


            {
                icon: "CircleIcon",
                title: "Demanda",
                children: [ 

                    {
                        icon: "CircleIcon",
                        title: "Ingreso Demanda",
                        route:
                        {
                            name: "dashboard-camanchaca-ingreso-contratos",
                        }
                    },                     

                    {
                        icon: "CircleIcon",
                        title: "Historico Demanda",
                        route:
                        {
                            name: "camanchaca-historico-demanda",
                        }
                    },        
                    
                    {
                        icon: "CircleIcon",
                        title: "Demanda Irrestricta",
                        route:
                        {
                            name: "camanchaca-demanda-irrestricta",
                        }
                    },                       


                ]
            },

*/


            /*
            {
                icon: "CircleIcon",
                title: "Ingreso Forecast",
                route:
                {
                    name: "dashboard-camanchaca-ingreso-forecast",
                }
            },
*/
            {
                icon: "CircleIcon",
                title: "Skus",
                route:
                {
                    name: "lorenzo-skus",
                }
            },

            {
                icon: "CircleIcon",
                title: "Demanda",
                route:
                {
                    name: "lorenzo-demanda",
                }
            },      
            
            {
                icon: "CircleIcon",
                title: "Lineas Productivas",
                route:
                {
                    name: "lorenzo-ajustes-lineas-productivas",
                }
            },


            /*
            {
                icon: "CircleIcon",
                title: "Freights",
                route:
                {
                    name: "dashboard-camanchaca-freights",
                }
            },
            
          

            {
                icon: "CircleIcon",
                title: "Ingreso Precios",
                route:
                {
                    name: "dashboard-camanchaca-precios",
                }
            },               



            {
                icon: "CircleIcon",
                title: "Historico Clientes",
                route:
                {
                    name: "camanchaca-historico-clientes",
                }
            },        
            
*/

            /*
            {
                icon: "CircleIcon",
                title: "Ajustes",
                route:
                {
                    name: "camanchaca-ajustes",
                }
            },   
*/
                        
            
        ]
    },

    {
        icon: "TrendingUpIcon",
        title: "Predicciones",
        children:
        [
            {
                icon: "CircleIcon",
                title: "Modelos de Predicción",
                route:
                {
                    name: "lorenzo-modelos-prediccion",
                }
            },
            {
                icon: "CircleIcon",
                title: "Histórico Predicciones",
                route:
                {
                    name: "lorenzo-historico-prediccion",
                }
            }
        ]
    },


    {
        icon: "LayersIcon",
        title: "Planificaciones",
        children:
        [
            /*
            {
                icon: "CircleIcon",
                title: "Nueva Planificación",
                route:
                {
                    name: "lorenzo-nueva-planificacion",
                }
            },*/

            {
                icon: "CircleIcon",
                title: "Nueva Planificación 2",
                route:
                {
                    name: "lorenzo-nueva-planificacion2",
                }
            },            
            {
                icon: "CircleIcon",
                title: "Histórico Planificación",
                route:
                {
                    name: "lorenzo-historico-planificaciones",
                }
            }            
        ]
    },
   
    {
        icon: "SettingsIcon",
        title: "Ajustes y restricciones",
        children:
        [

           
            {
                icon: "CircleIcon",
                title: "Ajustes",
                route:
                {
                    name: "lorenzo-ajustes",
                }
            },            
            


            /*
            {
                icon: "CircleIcon",
                title: "Cosecha y Produccion",
                route:
                {
                    name: "camanchaca-ajustes-cosecha-y-produccion",
                }
            },

            {
                icon: "CircleIcon",
                title: "Comerciales",
                route:
                {
                    name: "camanchaca-ajustes-comerciales",
                }
            },            

            */


            


            
        ]

        
    },

    {
        icon: "HelpCircleIcon",
        title: "Ayuda",
        children:
        [
            {
                icon: "CircleIcon",
                title: "FAQ",
                route:
                {
                    name: "lorenzo-faq",
                }
            },


            {
                icon: "CircleIcon",
                title: "Terminos y condiciones",
                route:
                {
                    name: "lorenzo-terminos-y-condiciones",
                }
            },

         
            


        ]

    }
        




]
