export default [

    { path: "/", redirect: { name: "lorenzo-home" } },

    // menu san lorenzo
    {
        path: "/csl/home",
        name: "lorenzo-home",
        component: () => import("@/views/clients/lorenzo/home/Home.vue"),
        meta: {
            pageTitle: "Inicio",
            breadcrumb: [
                {
                    text: "Consola de Administracion",
                    active: true
                }
            ]
        }
    },

    {
        path: "/csl/skus",
        name: "lorenzo-skus",
        component: () => import("@/views/clients/lorenzo/skus/Skus.vue"),
        meta: {
          pageTitle: "Skus",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo la vista inicial de cargar de formulario. ",
              text: "",
              active: true,
            },
          ],
        },
      },

      {
        path: "/csl/demanda",
        name: "lorenzo-demanda",
        component: () => import("@/views/clients/lorenzo/demanda/Demanda.vue"),
        meta: {
          pageTitle: "Demanda",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo la vista inicial de cargar de formulario. ",
              text: "",
              active: true,
            },
          ],
        },
      },

      {
        path: "/csl/carga-data",
        name: "lorenzo-carga-data",
        component: () =>
          import(
            "@/views/clients/lorenzo/carga-data/CargaData.vue"
          ),
        meta: {
          pageTitle: "Carga de Archivos",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo la vista inicial de nueva planificacion. ",
              text: "",
              active: true
            }
          ]
        }
      },

      {
        path: "/csl/ajustes",
        name: "lorenzo-ajustes",
        component: () => import("@/views/clients/lorenzo/ajustes/Ajustes.vue"),
        meta: {
          pageTitle: "Ajustes",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo la vista inicial de cargar de formulario. ",
              text: "",
              active: true,
            },
          ],
        },
      },

    {
        path: "/csl/faq",
        name: "lorenzo-faq",
        component: () =>
          import(
            "@/views/clients/camanchaca/faq/Faq.vue"
          ),
        meta: {
          pageTitle: "Preguntas Frecuentes",
          breadcrumb: [
            {
              text: "",
              active: true,
            },
          ],
        },
      },

      {
        path: "/csl/lineas-productivas",
        name: "lorenzo-ajustes-lineas-productivas",
        component: () =>
          import("@/views/clients/lorenzo/ajustes-restricciones/lineas-productivas/LineasProductivas.vue"),
        meta: {
          pageTitle: "Ajustes Lineas Productivas",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo un resumen de todas las planificaciones y análisis del potencial del stock disponible. ",
              text: "",
              active: true,
            },
          ],
        },
      },
            
      {
        path: "/csl/produccion",
        name: "lorenzo-ajustes-produccion",
        component: () =>
          import("@/views/clients/lorenzo/ajustes-restricciones/produccion/Produccion.vue"),
        meta: {
          pageTitle: "Ajustes Producción",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo un resumen de todas las planificaciones y análisis del potencial del stock disponible. ",
              text: "",
              active: true,
            },
          ],
        },
      },


      {
        path: "/csl/historico-notificaciones",
        name: "lorenzo-historico-notificaciones",
        component: () =>
          import(
            "@/views/clients/camanchaca/notificaciones/NotificationsList.vue"
          ),
        meta: {
          pageTitle: "Registro de Actividad",
          breadcrumb: [
            {
              text: "",
              active: true,
            },
          ],
        },
      },      
    

      {
        path: "/csl/nueva-planificacion",
        name: "lorenzo-nueva-planificacion",
        component: () =>
          import(
            "@/views/clients/lorenzo/nueva-planificacion/NuevaPlanificacion.vue"
          ),
        meta: {
          pageTitle: "Nueva Planificación",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo la vista inicial de nueva planificacion. ",
              text: "",
              active: true,
            },
          ],
        },
      },

      {
        path: "/csl/optimizations/compare-optimizations/",
        name: "compare-optimizations",
        component: () =>
          import(
            "@/views/clients/lorenzo/historico-optimizations/CompareOptimizations.vue"
          ),
        meta: {
          pageTitle: "Comparación de Optimizaciones",
          breadcrumb: [ 
            {
              text: "",
              active: true,
            },
          ],
        },
      },
      

      {
        path: "/csl/nueva-planificacion2",
        name: "lorenzo-nueva-planificacion2",
        component: () =>
          import(
            "@/views/clients/lorenzo/nueva-planificacion/NuevaPlanificacion2.vue"
          ),
        meta: {
          pageTitle: "Nueva Planificación",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo la vista inicial de nueva planificacion. ",
              text: "",
              active: true,
            },
          ],
        },
      },      

      {
        path: "/csl/historico-planificaciones",
        name: "lorenzo-historico-planificaciones",
        component: () =>
          import(
            "@/views/clients/lorenzo/planificaciones/HistoricoPlanificaciones.vue"
          ),
        meta: {
          pageTitle: "Histórico Planificaciones",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo la vista inicial de Historico planificaciones. ",
              text: "",
              active: true,
            },
          ],
        },
      },

      {
        path: "/csl/historico-planificaciones/detail/:planification_id",
        name: "lorenzo-planification-detail",
        component: () =>
          import(
            "@/views/clients/lorenzo/planificacion-detalle/DetallePlanificacion.vue"
          ),
        meta: {
          pageTitle: "Detalle de Planificación",
          breadcrumb: [
            {
              text: "",
              active: true,
            },
          ],
        },
      },

      {
        path: "/csl/prediccion/modelos",
        name: "lorenzo-modelos-prediccion",
        component: () =>
          import(
            "@/views/clients/lorenzo/ModuloNoActivado.vue"
          ),
        meta: {
          pageTitle: "Modelos de Predicción",
          breadcrumb: [
            {
              text: "",
              active: true,
            },
          ],
        },
      },
      {

      path: "/csl/prediccion/historico",
      name: "lorenzo-historico-prediccion",
      component: () =>
        import(
          "@/views/clients/lorenzo/ModuloNoActivado.vue"
          ),
      meta: {
        pageTitle: "Histórico de Predicciones",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },

    {
      path: "/csl/terminos-y-condiciones",
      name: "lorenzo-terminos-y-condiciones",
      component: () =>
        import(
          "@/views/clients/lorenzo/ayuda/terminosycondiciones.vue"
        ),
      meta: {
        pageTitle: "Preguntas Frecuentes",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },

    {
      path: "/csl/gantt",
      name: "lorenzo-planificacion-gantt",
      component: () =>
        import(
          "@/views/clients/lorenzo/planificaciones/PlanificacionGantt.vue"
        ),
      meta: {
        pageTitle: "Planificacion Gantt",
        breadcrumb: [
          {
            // text:
            //     "Estas viendo la vista inicial de Historico planificaciones. ",
            text: "",
            active: true,
          },
        ],
      },
    },


      

];
