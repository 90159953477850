export default [
  {
    path: "/",
    name: "molymet-home",
    component: () => import("@/views/clients/molymet/home/Home.vue"),
    meta: {
      pageTitle: "Inicio",
      breadcrumb: [
        {
          text: "Panel de inicio Molymet",
          active: true
        }
      ]
    }
  },
  {
    path: "/molymet/not-active",
    name: "not-active",
    component: () =>
      import(
        "@/views/clients/molymet/error/NotActive.vue"
      ),
    meta: {
      pageTitle: "No activo",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
		path: "/molymet/carga-data",
		name: "molymet-carga-data",
		component: () =>
			import("@/views/clients/molymet/data/carga-archivo/CargaData.vue"),
		meta: {
			pageTitle: "Carga de Archivo",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
  {
		path: "/molymet/data/mmpp/existencias/stock-existencias",
		name: "molymet-stock-existencias",
		component: () =>
			import("@/views/clients/molymet/data/materia-prima/existencias/IngresoStockExistencias.vue"),
		meta: {
			pageTitle: "Stock Existencias",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
  {
		path: "/molymet/data/mmpp/analitos/calidad",
		name: "molymet-calidad-analitos",
		component: () =>
			import("@/views/clients/molymet/data/materia-prima/analitos/IngresosCalidad.vue"),
		meta: {
			pageTitle: "Calidades Analitos",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},

  {
    path: "/molymet/blending/detalle-optimizacion",
    name: "molymet-detalle-optimizacion",
    component: () =>
      import("@/views/clients/molymet/blending/detalleOptimizacion/DetalleOptimizacion.vue"),
    meta: {
      pageTitle: "Detalle Optimización",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/molymet/blending/detalle-optimizacion-mezcla",
    name: "molymet-detalle-optimizacion-mezcla",
    component: () =>
      import("@/views/clients/molymet/blending/detalleOptimizacion/DetalleOptimizacionMezcla.vue"),
    meta: {
      pageTitle: "Detalle Mezcla",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },  

  {
    path: "/molymet/blending/sugerencia-mezcla",
    name: "molymet-sugerencia-mezcla",
    component: () =>
      import("@/views/clients/molymet/blending/SugerenciaMezcla.vue"),
    meta: {
      pageTitle: "Sugerencia de Mezcla",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/molymet/predicciones/historico-predicciones",
		name: "molymet-historico-predicciones",
		component: () =>
			import("@/views/clients/molymet/predicciones/PrediccionesAnalitos.vue"),
		meta: {
			pageTitle: "Predicciones analitos",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
  {
    path: "/molymet/blending/sugerencia-mezcla/comparar",
    name: "molymet-sugerencia-mezcla-comparar",
    component: () =>
      import("@/views/clients/molymet/blending/comparador/ComparadorMezclas.vue"),
    meta: {
      pageTitle: "Comparación Mezclas",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/molymet/blending/sugerencia-mezcla/:mezcla/:fecha",
    name: "molymet-sugerencia-mezcla-detalle",
    component: () =>
    import("@/views/clients/molymet/blending/detalle/DetalleSugerenciaMezcla.vue"),
    meta: {
      pageTitle: "Detalle de Mezcla",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/molymet/blending/sugerencia-mezcla/existencias-no-utilizadas",
    name: "molymet-existencias-no-utilizadas",
    component: () =>
      import("@/views/clients/molymet/blending/ExistenciasNoUtilizadas.vue"),
    meta: {
      pageTitle: "Existencias no Utilizadas",

      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
];

