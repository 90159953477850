
//import ApiServices from './apiServices'

import useMultitenant from '@/auth/multitenant/useMultitenant'

console.log(useMultitenant)

//const apiServices = new ApiServices()
const apiServices = useMultitenant.apiService
export default apiServices



