
import ApiServices from "../apiServices";
import axios from '@axios' 

export default class CMPApiServices extends ApiServices 
{
    constructor() {
        super("/cmp");

        this.modelsRead2 = this.baseUrl + "/models/read"

        this.modelsCreateInvocationUrl = this.baseUrl + "/models/create_invocation"
        this.modelsLastInvocationUrl = this.baseUrl + "/models/last_invocation"


        this.historicoInvocationsConfig = this.baseUrl + "/historicoInvocations/config"
        this.historicoInvocationsData = this.baseUrl + "/historicoInvocations/data"

        this.historicoOptimizationsConfig = this.baseUrl + "/historicoOptimizations/config"
        this.historicoOptimizationsData = this.baseUrl + "/historicoOptimizations/data"

        this.historicoOptimizationsAutoConfig = this.baseUrl + "/historicoOptimizationsAuto/config"
        this.historicoOptimizationsAutoData = this.baseUrl + "/historicoOptimizationsAuto/data"


        this.historicoPredictionsConfig = this.baseUrl + "/historicoPredictions/config"
        this.historicoPredictionsData = this.baseUrl + "/historicoPredictions/data"


        this.historicoAutomationResultsConfig = this.baseUrl + "/historicoAutomationResults/config"
        this.historicoAutomationResultsData = this.baseUrl + "/historicoAutomationResults/data"



        this.CV12historicoComparePredictionsConfig = this.baseUrl + "/comparePredictions/historicoComparePredictionsCV12/config"
        this.CV12historicoComparePredictionsData = this.baseUrl + "/comparePredictions/historicoComparePredictionsCV12/data"

        this.CV14historicoComparePredictionsConfig = this.baseUrl + "/comparePredictions/historicoComparePredictionsCV14/config"
        this.CV14historicoComparePredictionsData = this.baseUrl + "/comparePredictions/historicoComparePredictionsCV14/data"

        this.cv12Stats = this.baseUrl + "/comparePredictions/cv12Stats"
        this.cv14Stats = this.baseUrl + "/comparePredictions/cv14Stats"

        this.cv12FetPredictionsChart = this.baseUrl + "/comparePredictions/cv12FetPredictionsChart"
        this.cv12FeDttPredictionsChart = this.baseUrl + "/comparePredictions/cv12FeDttPredictionsChart"
        this.cv12FeMagPredictionsChart = this.baseUrl + "/comparePredictions/cv12FeMagPredictionsChart"

        this.cv14FetPredictionsChart = this.baseUrl + "/comparePredictions/cv14FetPredictionsChart"
        this.cv14FeDttPredictionsChart = this.baseUrl + "/comparePredictions/cv14FeDttPredictionsChart"
        this.cv14FeMagPredictionsChart = this.baseUrl + "/comparePredictions/cv14FeMagPredictionsChart"



        this.cv14PredictionsChart = this.baseUrl + "/comparePredictions/cv14PredictionsChart"

        ///////
        this.CV12historicoContinuousPredictionsConfig = this.baseUrl + "/continuousPredictions/historicoContinuousPredictionsCV12/config"
        this.CV12historicoContinuousPredictionsData = this.baseUrl + "/continuousPredictions/historicoContinuousPredictionsCV12/data"

        this.CV14historicoContinuousPredictionsConfig = this.baseUrl + "/continuousPredictions/historicoContinuousPredictionsCV14/config"
        this.CV14historicoContinuousPredictionsData = this.baseUrl + "/continuousPredictions/historicoContinuousPredictionsCV14/data"

        this.cv12StatsContinuous = this.baseUrl + "/continuousPredictions/cv12Stats"
        this.cv14StatsContinuous = this.baseUrl + "/continuousPredictions/cv14Stats"

        this.cv12ContinuousPredictionsChart = this.baseUrl + "/continuousPredictions/cv12PredictionsChart"
        this.cv14ContinuousPredictionsChart = this.baseUrl + "/continuousPredictions/cv14PredictionsChart"

        /////////

        this.comparePredictions2 = {

            stats: this.baseUrl + "/comparePredictions2/stats",
            predictionsChart: this.baseUrl + "/comparePredictions2/predictionsChart",

            historicoComparePredictionsTable: this.baseUrl + "/comparePredictions2/historicoComparePredictionsTable",


        }

        this.detalleOptimizacion = {

            detalleOptimizacionTable: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable",
            detalleOptimizacionTable2: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable2",
            detalleOptimizacionParallelChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionParallelChart",

            detalleOptimizacionStats: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats",
            detalleOptimizacionStats2: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats2",
            detalleOptimizacionStats3: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats3",

            detalleOptimizacion8HorasStats: this.baseUrl + "/detalleOptimization/detalleOptimizacion8HorasStats",
            detalleOptimizacion8HorasStats2: this.baseUrl + "/detalleOptimization/detalleOptimizacion8HorasStats2",
            detalleOptimizacion8HorasStats3: this.baseUrl + "/detalleOptimization/detalleOptimizacion8HorasStats3",



            detalleOptimizacionGensChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionGensChart",


            detalleOptimizacionMezclaChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclaChart",
            detalleOptimizacionMezclaChart2: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclaChart2",
            detalleOptimizacionMezclaChart3: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclaChart3",

            detalleOptimizacionMezcla8HorasChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezcla8HorasChart",
            detalleOptimizacionMezcla8HorasChart2: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezcla8HorasChart2",
            detalleOptimizacionMezcla8HorasChart3: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezcla8HorasChart3",
            

            detalleOptimizacionDownloadExcel: this.baseUrl + "/detalleOptimization/detalleOptimizacionDownloadExcel"
            

        };



 
        this.historicoPrediccionesMezclas = {

            historicoPrediccionesMezclasStats: this.baseUrl + "/historicoPrediccionesMezclas/historicoPrediccionesMezclasStats",


            
            

        };



        this.predictions = {

            CV12LastPredictionFetChart: this.baseUrl + "/predictions/CV12LastPredictionFetChart",
            CV12LastPredictionFeDTTChart: this.baseUrl + "/predictions/CV12LastPredictionFeDTTChart",
            CV12LastPredictionFeMagChart: this.baseUrl + "/predictions/CV12LastPredictionFeMagChart",
            
            
            CV14LastPredictionFetChart: this.baseUrl + "/predictions/CV14LastPredictionFetChart",
            CV14LastPredictionFeDTTChart: this.baseUrl + "/predictions/CV14LastPredictionFeDTTChart",
            CV14LastPredictionFeMagChart: this.baseUrl + "/predictions/CV14LastPredictionFeMagChart",




            statisticsCV12: this.baseUrl + "/predictions/statisticsCV12",
            statisticsCV14: this.baseUrl + "/predictions/statisticsCV14",
        }




        this.compareInvocations = {
            compareInvocationsMetrics: this.baseUrl + "/compareInvocations/compareInvocationsMetrics",

            compareInvocationsMetricsTable: this.baseUrl + "/compareInvocations/compareInvocationsMetricsTable",


            compareInvocationsMetricsParallel: this.baseUrl + "/compareInvocations/compareInvocationsMetricsParallel",
            compareAllInvocationsMetricsParallel: this.baseUrl + "/compareInvocations/compareAllInvocationsMetricsParallel",


        }
       

        this.compareOptimizations = {
            compareOptimizationsMetrics: this.baseUrl + "/compareOptimizations/compareOptimizationsMetrics",
            compareOptimizationsMetricsTable: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsTable",
            compareOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsParallel",
            compareAllOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareAllOptimizationsMetricsParallel",

        }
    
       
    }

    modelsRead(data, vue) {
        return axios.post(this.modelsRead2, data)

    }


  }

  
