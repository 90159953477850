
import ApiServices from "../apiServices";

import axios from '@axios' 


export default class SclApiServices extends ApiServices 
{
    constructor() {
      super("/csl");
  
      // subject and grade are specific to Teacher
      //this.historicoCargaConfig = this.baseUrl + "/api/historicoCarga/configSCL"


      this.skusCreate = this.baseUrl + "/skus/create"
      this.skusDelete = this.baseUrl + "/skus/delete"

      this.historicoPlanificacionesConfig = this.baseUrl + "/historicoPlanificaciones/config"
      this.historicoPlanificacionesData = this.baseUrl + "/historicoPlanificaciones/data"
      this.historicoPlanificacionesEdit = this.baseUrl + "/historicoPlanificaciones/edit" 
  

      this.historicoOrdersConfig = this.baseUrl + "/orders/historico/config"
      this.historicoOrdersData = this.baseUrl + "/orders/historico/data"
      this.historicoOrdersEdit = this.baseUrl + "/orders/historico/edit" 
  
      

      this.orderCreate = this.baseUrl + "/orders/create"
      this.orderDelete = this.baseUrl + "/orders/delete"


      this.optimizations = {

        create: this.baseUrl + "/optimizations/create",



    };

             
      this.compareOptimizations = {
        compareOptimizationsMetrics: this.baseUrl + "/compareOptimizations/compareOptimizationsMetrics",
        compareOptimizationsMetricsTable: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsTable",
        compareOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsParallel",
        compareAllOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareAllOptimizationsMetricsParallel",

        compareOptimizationsFOB: this.baseUrl + "/compareOptimizations/compareOptimizationsFOBTable",
        

    }
   



      this.nuevaPlanificacion = {
    
        orders: {

            config: this.baseUrl + "/nuevaPlanificacion/orders/config",
            data: this.baseUrl + "/nuevaPlanificacion/orders/data"
        },

        lineas: {

            config: this.baseUrl + "/nuevaPlanificacion/lineas/config",
            data: this.baseUrl + "/nuevaPlanificacion/lineas/data"
        }

      }

      this.detalleOptimizacion = {

        detalleOptimizacionStats: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats",

    };


    this.settingsPhasingEntregaTable = this.baseUrl + "/settings/phasingEntregaTable";
    this.settingsMatrizCambiosTable = this.baseUrl + "/settings/matrizCambiosTable";

    


    }

    postSkusCreate(data, vue)
    {
        return axios.post(this.skusCreate, data)

    }
    postSkusDelete(data, vue)
    {
        return axios.post(this.skusDelete, data)

    }


    postOrderCreate(data, vue)
    {
        return axios.post(this.orderCreate, data)

    }

    postOrderDelete(data, vue)
    {
        return axios.post(this.orderDelete, data)

    }

    postLineasDelete(data, vue)
    {
        return axios.post(this.lineasDelete, data)

    }    

    optimizationsCreate(data, vue) {
        return axios.post(this.optimizations.create, data)
    }


  }

  
