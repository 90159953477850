import axios from '@axios'
import ApiServices from "../apiServices";


export default class MolymetApiServices extends ApiServices {
  constructor() {
    super("/molymet");

    this.documentsUploadDocument = this.baseUrl + "/documents/uploadDocument"
    this.documentsDownloadTemplate = this.baseUrl + "/documents/downloadTemplate"

    this.historicoCargaConfig = this.baseUrl + "/historicoCarga/config"
    this.historicoCargaData = this.baseUrl + "/historicoCarga/data"
    this.historicoCargaStatistics = this.baseUrl + "/historicoCarga/statistics"

    this.analitosCreate = this.baseUrl + "/analitos/create"
    this.analitosConfig = this.baseUrl + "/analitos/config"
    this.analitosData = this.baseUrl + "/analitos/data"
    this.analitosEdit = this.baseUrl + "/analitos/edit"
		this.analitosDelete = this.baseUrl + "/analitos/eliminar"



    this.historicoStockExistenciasConfig = this.baseUrl + "/data/materia-prima/existencias/config"
    this.historicoStockExistenciasData = this.baseUrl + "/data/materia-prima/existencias/data"
    this.historicoStockExistenciasCreate = this.baseUrl + "/data/materia-prima/existencias/create"
    this.historicoStockExistenciasEdit = this.baseUrl + "/data/materia-prima/existencias/edit"
    this.deleteStockExistencias = this.baseUrl + "/data/materia-prima/existencias/delete"
		this.historicoExistenciasIndex = this.baseUrl + "/data/materia-prima/existencias/fechas"



    this.historicoBlendingSugerenciasConfig = this.baseUrl + "/historicoBlendingSugerencias/config"
    this.historicoBlendingSugerenciasData = this.baseUrl + "/historicoBlendingSugerencias/data"

    this.modelsCreateInvocationUrl = this.baseUrl + "/models/create_invocation"
    this.historicoPrediccionesMolymetStatistics = this.baseUrl + "/predicciones/statistics"
    this.chartProcedencia= this.baseUrl + "/predicciones/chart"

    this.compareBlending = {
      Metrics: this.baseUrl + "/compareBlending/metrics",
      MetricsTable: this.baseUrl + "/compareBlending/metricsTable",
      MetricsParallel: this.baseUrl + "/compareBlending/metricsParallel",
      MetricsKg: this.baseUrl + "/compareBlending/metricsKg",
    }

    this.historicoExistenciasNoUtilizadasConfig = this.baseUrl + "/blending/historicoExistenciasNoUtilizadas/config"
    this.historicoExistenciasNoUtilizadasData = this.baseUrl + "/blending/historicoExistenciasNoUtilizadas/data"

    this.detallesMezclaConfig = this.baseUrl + "/detallesMezclaBlending/config"
		this.detallesMezclaData = this.baseUrl + "/detallesMezclaBlending/data"
		this.detallesMezclaStatistics = this.baseUrl + "/detallesMezclaBlending/statistics"


    this.historicoPrediccionesConfig = this.baseUrl + "/historicoPredicciones/config"
    this.historicoPrediccionesData = this.baseUrl + "/historicoPredicciones/data"

    this.detalleOptimizacion = {

      detalleOptimizacionMezclasTable: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclasTable",
      detalleOptimizacionMezclasTable2: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclasTable2",
      detalleNoUtilizados: this.baseUrl + "/detalleOptimization/detalleNoUtilizados",

      editDetalleOptimizacion: this.baseUrl + "/detalleOptimization/editDetalleOptimizacion",


      detalleOptimizacionTable: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable",
      detalleOptimizacionTable2: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable2",
      detalleOptimizacionParallelChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionParallelChart",

      detalleOptimizacionStats: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats",
      detalleOptimizacionStats2: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats2",
      detalleOptimizacionStats3: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats3",

      detalleOptimizacion8HorasStats: this.baseUrl + "/detalleOptimization/detalleOptimizacion8HorasStats",
      detalleOptimizacion8HorasStats2: this.baseUrl + "/detalleOptimization/detalleOptimizacion8HorasStats2",
      detalleOptimizacion8HorasStats3: this.baseUrl + "/detalleOptimization/detalleOptimizacion8HorasStats3",



      detalleOptimizacionGensChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionGensChart",


      detalleOptimizacionMezclaChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclaChart",
      detalleOptimizacionMezclaChart2: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclaChart2",
      detalleOptimizacionMezclaChart3: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclaChart3",

      detalleOptimizacionMezcla8HorasChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezcla8HorasChart",
      detalleOptimizacionMezcla8HorasChart2: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezcla8HorasChart2",
      detalleOptimizacionMezcla8HorasChart3: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezcla8HorasChart3",
      

      detalleOptimizacionDownloadExcel: this.baseUrl + "/detalleOptimization/detalleOptimizacionDownloadExcel"
      

  };




  }

  postStockExistenciaCreate(data){
    return axios.post(this.historicoStockExistenciasCreate, data)
  }

  deleteStockExistencia(id) {
    return axios.delete(this.deleteStockExistencias + "/" + id)
  }

  postAnalitosCreate(data, vue) {
    return axios.post(this.analitosCreate, data)
  }
  deleteAnalito(id) {
		return axios.delete(this.analitosDelete + "/" + id);
	}

  postSimulacionesCreate(data, vue) {
    return axios.post(this.modelsCreateInvocationUrl, data)
  }

  getFechasExistencias(vue) {
		return axios.get(this.historicoExistenciasIndex);

	}
}



