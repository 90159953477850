export default [
  { path: "/", redirect: { name: "cmp-home" } },



  {
    path: "/home",
    name: "cmp-home",
    component: () => import("@/views/clients/cmp/home/Home.vue"),
    meta: {
      pageTitle: "Inicio",
      breadcrumb: [
        {
          text: "Consola de Administración",
          active: true,
        },
      ],
    },
  },

  {
    path: "/cmp-ajustes",
    name: "cmp-ajustes",
    component: () => import("@/views/clients/cmp/ajustes/Ajustes.vue"),
    meta: {
      pageTitle: "Ajustes y Restricciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },


  {
    path: "/cmp-simulacion-escenarios",
    name: "cmp-simulacion-escenarios",
    component: () =>
      import(
        "@/views/clients/cmp/optimizaciones/HistoricoPlanificaciones.vue"
      ),
    meta: {
      pageTitle: "Simulación de Escenarios",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/historico-predicciones-mezclas",
    name: "cmp-historico-predicciones-mezclas",
    component: () =>
      import(
        "@/views/clients/cmp/optimizaciones/HistoricoPrediccionesMezclas.vue"
      ),
    meta: {
      pageTitle: "Historico de Predicciones y Mezclas",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/historico-comparacion-predicciones",
    name: "cmp-historico-comparacion-predicciones",
    component: () =>
      import(
        "@/views/clients/cmp/comparacion-predicciones/HistoricoComparacionPrediccion.vue"
      ),
    meta: {
      pageTitle: "Real vs Predicción",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/historico-comparacion-predicciones2",
    name: "cmp-historico-comparacion-predicciones2",
    component: () =>
      import(
        "@/views/clients/cmp/comparacion-predicciones2/HistoricoComparacionPrediccion.vue"
      ),
    meta: {
      pageTitle: "Real vs Predicción",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },



  {
    path: "/historico-comparacion-prediccion-continua",
    name: "cmp-historico-comparacion-prediccion-continua",
    component: () =>
      import(
        "@/views/clients/cmp/prediccion-continua/HistoricoComparacionPrediccion.vue"
      ),
    meta: {
      pageTitle: "Predicción Continua",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/historico-data",
    name: "cmp-historico-data",
    component: () =>
      import(
        "@/views/clients/cmp/historico-automation-results/HistoricoAutomationResults.vue"
      ),
    meta: {
      pageTitle: "Histórico de Data",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },




  {
    path: "/optimizations/details/",
    name: "optimization-details",
    component: () =>
      import(
        "@/views/clients/cmp/optimizaciones/DetallePlanificacion.vue"
      ),
    meta: {
      pageTitle: "Detalle de Optimización",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },


  {
    path: "/predicciones/compare-simulations/",
    name: "compare-simulations",
    component: () =>
      import(
        "@/views/clients/cmp/optimizaciones/CompareOptimizations.vue"
      ),
    meta: {
      pageTitle: "Comparación de Simulaciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/terminos-y-condiciones",
    name: "cmp-terminos-y-condiciones",
    component: () =>
      import(
        "@/views/clients/cmp/ayuda/terminosycondiciones.vue"
      ),
    meta: {
      pageTitle: "Terminos y condiciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/faq",
    name: "cmp-faq",
    component: () =>
      import(
        "@/views/clients/cmp/ayuda/faq/Faq.vue"
      ),
    meta: {
      pageTitle: "Preguntas frecuentes",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/registro",
    name: "cmp-registro",
    component: () =>
      import(
        "@/views/clients/cmp/ayuda/RegistroActividades.vue"
      ),
    meta: {
      pageTitle: "Registro de Actividades",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
];
